import Carousel from "better-react-carousel";
import BudhaHeadLogo from "../Assets/budha-head-logo.png";
import ChampionsImage1 from "../Assets/champion-image-1.jpg";
import ChampionsImage2 from "../Assets/champion-image-2.jpg";
import ChampionsImage3 from "../Assets/champion-image-3.jpg";
import ChampionsImage4 from "../Assets/champion-image-4.jpg";
import ChampionsImage5 from "../Assets/champion-image-5.jpg";
import ChampionsImage6 from "../Assets/champion-image-6.jpg";

const OurChampionsSection = () => {
  return (
    <div className="champions-main-section">
      <Carousel loop={true} autoplay={10000} gap={50}>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage1}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                Life!! Most of us never knows it’s real essence, the true value
                of being alive penetrates in our mind when we are lying on death
                bed…or when we lose some one very close to our soul & heart.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage2}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                This is how a healthy lifestyle brought lot of changes in my
                overall health. There is no age bar for being healthy and fit.
                Good lifestyle helped me not only to achieve my physical goals but
                also helped me to stay healthy and kept away medicines and health
                issues and assisted in gaining high energy level.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage3}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                I come in contact with Yogendera & Manju through my one of
                my closet friend (Ranjeet). They both being a great mentor in
                the course of my journey of being mentally and physically fit in
                order to discover my better version each time. And to boosted me up to regain
                inner strength believing myself that I can do. Moreover, I
                have overcome my many psychological and physical setbacks.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage4}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                I am Shalu from jaipur. I am a homemaker. I connected with Manju
                and Yogender in 2020. When I met them, I was overweight and had
                PCOD with kidney issues.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage5}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                I has been dealing with THYROID, I was on thyroid medication
                since i was of 16 years and other issues like FATIGUE, Skin
                issue like wart , TENDONITIS PAIN etc. But I am having
                autoimmune issue which I realized when I sent old reports to
                Manju. Look how lifestyle change helped me get better with all
                my problems. I started at 157 pound and came down to 143 pound.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="champions-main-container">
            <img
              className="champions-image"
              src={ChampionsImage6}
              alt="feedbacks"
            />
            <div className="champions-main-text-container">
              <img width="25%" src={BudhaHeadLogo} alt="lean budha vector" />
              <h2 className="champions-main-headig">
                Meet Our <br />
                Champions
              </h2>
              <p className="champions-main-paragraph">
                I have been in the manufacturing industry for 15 years, where
                shifts keep on changing and bring high stress, poor
                eating habits, and no exercise regime. This became my daily routine resulting in weight gain around belly, and it disturbed my
                digestive system and overall gut health.
              </p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default OurChampionsSection;
