import React from "react";
import Carousel from "better-react-carousel";
import halfLotus from '../Assets/half-lotus-logo.png';
import fullLotus from '../Assets/full-lotus.png';
import mediumLotus from '../Assets/medium-lotus.png';
import fullHalfLotus from '../Assets/full-half-lotus.png';

const BudhaPhraseSection = () => {
  return (
    <div className="budha-phrase-section">
      <Carousel loop={true} hideArrow autoplay={6000} gap={50}>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={halfLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“Health is not just absence of diseases.”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={fullLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“In this world the gift of "Health and Hope" is of higher order.”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={mediumLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“The absence of both is pain, suffering, disease, depression and end of life.”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={fullHalfLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“We live and die at cellular level every day.”</p>
            </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={halfLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“Being fit and healthy is simple but not easy.”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={fullLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“Being healthy is possible with multidimensional approach only”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={mediumLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“What it takes to heal ourselves is lifestyle changes.”</p>
        </Carousel.Item>
        <Carousel.Item>
            <img className="budha-phrase-logo" src={fullHalfLotus} alt="a flower that is lotus"/>
            <p className="budha-phrase">“Health is not valued until sickness comes.”</p>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default BudhaPhraseSection;
