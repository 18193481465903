import mywellnessLogo from "../Assets/mywellness-logo.png";


const Header = () => {


    return (
        <div>
            <header className='header'>
                <div className='header-container'>
                    <img className='header-logo' src={mywellnessLogo} alt='Lord budha'/>
                    <div className='header-text-container'>
                        <h2 className='header-heading'>MY Wellness</h2>
                        <p className='header-paragraph'>With Manju & Yogen</p>
                    </div>
                </div>
            </header>
        </div>
    )
};

export default Header;