import Carousel from "better-react-carousel";
import FeedbackImage1 from "../Assets/feedback-image-1.jpg";
import FeedbackImage2 from "../Assets/feedback-image-2.jpg";
import FeedbackImage3 from "../Assets/feedback-image-3.jpg";
import FeedbackImage4 from "../Assets/feedback-image-4.jpg";
import FeedbackImage5 from "../Assets/feedback-image-5.jpg";
import FeedbackImage6 from "../Assets/feedback-image-6.jpg";
import FeedbackImage7 from "../Assets/feedback-image-7.jpg";
import FeedbackImage8 from "../Assets/feedback-image-8.jpg";
import FeedbackImage9 from "../Assets/feedback-image-9.jpg";
import LeanBudha2 from "../Assets/lean-budha-logo-2.png";

const FeedbackSection = () => {
  return (
    <div className="feedback-main-section">
      <Carousel loop={true} autoplay={8000} gap={50}>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Thanks for taking this initiative and sharing your knowledge
                with us. It helped us to develop a good habit. Every morning it
                was like a goal to achieve. Small but significant step towards
                healthy life. Felt good after each detox drink !! Thanks much
                and Gratitude.
                <br />
                <br />
                <span className="feedback-write-name">....Ritcha, USA</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage1}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                I felt energised for the whole day.
                <br />
                <br />
                was facing indigestion problem before, now feeling much better.
                <br />
                Thank you Mr. & Mrs. Yadav... keep up the good work.
                <br />
                <br />
                <span className="feedback-write-name">
                  ....Akash Jaishwal, Jharkhand
                </span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage2}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                I used to feel full and tight at tummy just after eating two
                chapati but now I feel light. <br /> I am observing flexibility
                at tummy. Bowel movement also improved.
                <br />
                <br />
                <span className="feedback-write-name">....Kalpana, Kota</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage3}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Thank you for sharing simple yet effective ways to detox our
                body. The pros which I feel are:
                <br />
                - Feeling very lighter body,
                <br />
                - More energetic,
                <br />
                - Better and deep sleep.
                <br />
                Cons: Taste of coriander seeds water.
                <br />
                <br />
                <span className="feedback-write-name">
                  ....Gaurav Sharma, Nagpur
                </span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage4}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Thanks Sir for sharing this knowledge with us. It was good to
                know about each ingredient what they do to cleanse our body each
                day. Felt fresh and good after taking each detox drink. Will
                continue this detox for a healthy lifestyle.
                <br />
                <br />
                <span className="feedback-write-name">....Priya, USA</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage5}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Thank you Manju and Yogender
                <br /> My gut is feeling very happy now and it also helps me to
                realise to take care of gut is important for a healthy body.
                <br />
                <br />
                <span className="feedback-write-name">....Hemlata</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage6}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Dear Yogen,
                <br /> Really enjoyed the detox program. Although I missed 2
                sessions but will definitely try to do it routinely. Your
                initiative was overwhelming.
                <br />
                <br />
                <span className="feedback-write-name">....Virendra Kumar</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage7}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Yogender bhai stomach bhot halka rha 5 din
                <br /> Na koi acidity hui na koi heaviness feel ..
                <br /> Mother and wife ne bhi join kiya tha..
                <br />
                Thank you for your support.
                <br />
                <br />
                <span className="feedback-write-name">....Gaurav</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage8}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="feedback-main-container">
            <div className="feedback-main-text-container">
              <img width="25%" src={LeanBudha2} alt="lean budha vector" />
              <h2 className="feedback-main-headig">Feedback</h2>
              <p className="feedback-main-paragraph">
                Thank you, it was very good and exciting experience for me as I
                got to know many things about my gut system and reall enjoyed it
                however I missed it 2 days but it was really very awesome
                <br />
                <br />
                <span className="feedback-write-name">....Ragini</span>
              </p>
            </div>
            <img
              className="feedback-image"
              src={FeedbackImage9}
              alt="feedbacks"
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default FeedbackSection;
