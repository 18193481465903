const FivePiller = () => {
  return (
    <div className="five-piller-section">
      <div className="five-piller-card-container-1">
        <h3 className="five-piller-heading">Foods</h3>
        <p className="five-piller-paragraph">
          What we eat is who we are. Food is not only fuel for us but it
          nourishes us and it is raw material for the production of hormones in
          our body. But sadly, the food that we eat is no longer pure and
          effective as it was for our grandparents. We help you decode the
          buzzwords of today - organic, non-GMO, alkaline etc.
        </p>
      </div>
      <div className="five-piller-card-container-2">
        <h3 className="five-piller-heading">Exercise</h3>
        <p className="five-piller-paragraph">
          We all know the importance of exercise or physical activities. But
          most of us have difficulty in designing our regime, which form of
          exercise to include or not to include. Which type of exercise is most
          suited for your body type ? What are the Do’s and Don'ts of each type
          of exercise?
        </p>
      </div>
      <div className="five-piller-card-container-3">
        <h3 className="five-piller-heading">Toxins</h3>
        <p className="five-piller-paragraph">
          The air we breathe, the water we drink, the food we eat, the cosmetics
          we use - can wellness be achieved without making sure these are pure?
          The pollution around us is omni-present. Learn from us that how to
          undo the effects of pollution and toxicity in everyday life.
        </p>
      </div>
      <div className="five-piller-card-container-4">
        <h3 className="five-piller-heading">Sleep</h3>
        <p className="five-piller-paragraph">
          We know that sleep time is repair & healing time for our body. But how
          to get deep and refreshing sleep? Which of our habits are supporting
          or depriving us of sleep? What are the different kinds of sleep? Get
          shared answers to these questions from us.
        </p>
      </div>
      <div className="five-piller-card-container-5">
        <h3 className="five-piller-heading">Stress</h3>
        <p className="five-piller-paragraph">
          Stress is one of the biggest silent killers by elevating the oxidative
          stress in our bodies. Most people go through it without realising that
          they have it, that makes it more deadly? Have you measured your stress
          levels? How to deal with it?
        </p>
      </div>
      <div className="five-piller-card-container-6">
        <h3 className="five-piller-heading">Environment</h3>
        <p className="five-piller-paragraph">
          Emotional wellness is the core of overall wellness. Do you feel you
          belong at the place you work? Do you have enough emotional connections
          with your co-workers, your community, your society, your family to
          drive your wellness long term?
        </p>
      </div>
    </div>
  );
};

export default FivePiller;
