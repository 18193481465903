import { useRef } from "react";
import MywellnessWhiteLogo from "../Assets/mywellness-logo-white.png";

const Footer = () => {
  const email = () => {
    window.open("mailto: yogen3028@gmail.com");
  };
  const mobileCall = () => {
    window.open("tel: +919728682150");
  };

  const formEnquiry = useRef(null);

  const handleForm = async (e) => {
    e.preventDefault();
    const formEnquiryData = new FormData(formEnquiry.current);

    let data = {};
    for (var key of formEnquiryData.keys()) {
      data[key] = formEnquiryData.get(key);
    }
    console.log("Form Submitted", data);

    // Send data to the backend via POST
    await fetch(
      "https://gsrwj6az64.execute-api.ap-northeast-1.amazonaws.com/default/myEmail",
      {
        // Enter your IP address here

        method: "POST",
        //   mode: 'cors',
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    ).then((response) => {});

    alert("We will get back to you shortly");
    window.location.reload();
  };

  const year = Number(new Date().getFullYear().toString());

  return (
    <div>
      <div className="footer-section-1-container">
        <div className="footer-card-container-1">
          <div className="footer-logo-container">
            <img
              className="footer-logo"
              src={MywellnessWhiteLogo}
              alt="Lord budha"
            />
            <div className="footer-text-container">
              <h2 className="footer-heading">MY Wellness</h2>
              <p className="footer-paragraph">With Manju & Yogen</p>
            </div>
          </div>
        </div>
        <div className="footer-card-container-2">
          <h2 className="footer-2nd-heading">
            Get my daily tips on mindful living
          </h2>
          <form ref={formEnquiry} onSubmit={e => handleForm(e)}>
            <label className="footer-form-label">Email</label>
            <br />
            <div className="footer-form-container">
              <input className="footer-form-input" type="email" name='email'></input>
              <button className="footer-form-btn-send">Subscribe</button>
            </div>
          </form>
        </div>
        <div className="footer-card-container-3">
          <p className="footer-2nd-heading" >
            My Wellness by Yogen & Manju <br />
            <span className="footer-email-link" onClick={email}>Mail: yogen3028@gmail.com</span> <br />
            <span className="footer-mobile-link" onClick={mobileCall}>Phone number: 9728682150</span>
          </p>
        </div>
      </div>
      <div className="footer-copyright-section">
        <p className="footer-copyright-paragraph">
          © {year} by My Wellness. Powered by Drishna Technologies
        </p>
      </div>
    </div>
  );
};

export default Footer;
