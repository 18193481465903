import { useRef } from "react";
import HeroMainImage from "../Assets/hero-image-main.png";

const HeroSection = () => {
  const formEnquiry = useRef(null);

  const handleForm = async (e) => {
    e.preventDefault();
    const formEnquiryData = new FormData(formEnquiry.current);

    let data = {};
    for (var key of formEnquiryData.keys()) {
      data[key] = formEnquiryData.get(key);
    }
    console.log("Form Submitted", data);

    // Send data to the backend via POST
    await fetch(
      "https://gsrwj6az64.execute-api.ap-northeast-1.amazonaws.com/default/myEmail",
      {
        // Enter your IP address here

        method: "POST",
        //   mode: 'cors',
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    ).then((response) => {});

    alert("We will get back to you shortly");
    window.location.reload();
  };

  return (
    <div className="hero-main-section">
      <div className="hero-text-form-container">
        <div>
          <h2 className="hero-main-heading">
            Our body is
            <br />
            the only place
            <br /> where we live.
          </h2>
          <p className="hero-main-paragraph">Let's simplify the health</p>
        </div>
        <div className="hero-main-form-container">
          <p className="hero-main-form-heading">
            Mind your mailbox for new post
          </p>
          <form ref={formEnquiry} onSubmit={(e) => handleForm(e)}>
            <label className="hero-main-form-label">Email*</label>
            <br />
            <div className="hero-main-form-input-container">
              <input
                className="hero-main-form-input"
                type="email"
                name="email"
              />
              <button className="hero-main-form-btn-subscribe">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
      <img
        className="hero-image-main"
        src={HeroMainImage}
        alt="a girl near beach"
      />
    </div>
  );
};

export default HeroSection;
