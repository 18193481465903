
import './App.css';
import Header from './Components/Header';
import HeroSection from './Components/HeroSection';
import BudhaPhraseSection from './Components/BudhaPhraseSection';
import FivePiller from './Components/FivePiller';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';
import FeedbackSection from './Components/FeedbackSection';
import AddEventSection from './Components/AddEventSection';
import OurChampionsSection from './Components/OurChampionsSection';
import Footer from './Components/Footer';


function App() {

  const facebook = ()=>{
    window.open("https://www.facebook.com/yogender.yadav.10?mibextid=ZbWKwL", "_blank").focus();
  }

  const linkedIn = ()=>{
    window.open("https://www.linkedin.com/in/manju-yadav-b2748021a ", "_blank").focus();
  }

  const youTube = ()=>{
    window.open("https://www.youtube.com/@Yogender_Yadav_3028", "_blank").focus();
  }

  const instagram = ()=>{
    window.open("https://www.instagram.com/invites/contact/?i=wuxaailrs6xt&utm_content=26x9f4e", "_blank").focus();
  }

  return (
    <div className="App">
      <div className='social-media-container'>
        <FaFacebookF  onClick={facebook} className='social-media-icon-facebook' />
        <FaLinkedin  onClick={linkedIn} className='social-media-icon-twitter' />
        <FaYoutube  onClick={youTube} className='social-media-icon-youtube' />
        <FaInstagram  onClick={instagram} className='social-media-icon-instagram' />

      </div>
      <Header/>
      <HeroSection/>
      <BudhaPhraseSection/>
      <FivePiller/>
      <AddEventSection/>
      <OurChampionsSection/>
      <FeedbackSection/>
      <Footer/>
     
    </div>
  );
}

export default App;
