import { useRef } from "react";
import EventImage from "../Assets/event-image-1.png";
import LeanBudha from "../Assets/lean-budha-logo.png";

const AddEventSection = () => {
  const formEnquiry = useRef(null);

  const handleForm = async (e) => {
    e.preventDefault();
    const formEnquiryData = new FormData(formEnquiry.current);

    let data = {};
    for (var key of formEnquiryData.keys()) {
      data[key] = formEnquiryData.get(key);
    }
    console.log("Form Submitted", data);

    // Send data to the backend via POST
    await fetch('https://gsrwj6az64.execute-api.ap-northeast-1.amazonaws.com/default/myEmail', {  // Enter your IP address here

      method: 'POST', 
    //   mode: 'cors', 
      body: JSON.stringify(data) // body data type must match "Content-Type" header

    }).then((response) =>{})

    alert('We will get back to you shortly')
    window.location.reload()
   
  }


  return (
    <div className="addevent-main-secion">
      <div className="addevent-subsection-1">
        <img
          className="addevent-main-image"
          src={EventImage}
          alt="A man showing their events"
        />
      </div>
      <div className="addevent-subsection-2">
        <div>
          <div className="addevent-lean-budha-logo-container">
            <img
              className="addevent-lean-budha-logo"
              src={LeanBudha}
              alt="lean budha vector"
            />
          </div>
          <h2 className="addevent-main-heading">Upcoming Events</h2>
          <p className="addevent-main-paragraph">Book your slot now</p>
        </div>
        <form
          className="addevent-form-container"
          ref={formEnquiry}
          onSubmit={(e) => handleForm(e)}
        >
          <label className="addevent-form-lable">Full Name</label>
          <br />
          <input
            className="addevent-form-input"
            type="text"
            name="name"
          ></input>
          <br />
          <br />
          <label className="addevent-form-lable">Mobile Number</label>
          <br />
          <input
            className="addevent-form-input"
            type="number"
            name="mobile"
          ></input>
          <br />
          <br />
          <label className="addevent-form-lable">Email</label>
          <br />
          <input
            className="addevent-form-input"
            type="email"
            name="email"
          ></input>
          <br />

          <button className="addevent-btn-send">Send</button>
        </form>
      </div>
    </div>
  );
};

export default AddEventSection;
